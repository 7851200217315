import React from "react";
import { object } from "prop-types";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Layout } from "components/Layout";

const AboutMePage = ({ location, data }) => (
  <Layout location={location} title={data.mdx.frontmatter.pageTitle}>
    <MDXRenderer
      localImages={data.mdx.frontmatter.localImages}
      localDocuments={data.mdx.frontmatter.localDocuments.map(
        (s) => s.publicURL
      )}
      localVideos={data.mdx.frontmatter.localVideos.map((s) => s.publicURL)}
    >
      {data.mdx.body}
    </MDXRenderer>
  </Layout>
);

export const query = graphql`
  query {
    mdx(slug: { eq: "about" }) {
      id
      body
      frontmatter {
        localImages {
          childImageSharp {
            gatsbyImageData
          }
        }
        localDocuments {
          publicURL
        }
        localVideos {
          publicURL
        }
        pageTitle
      }
    }
  }
`;

AboutMePage.propTypes = {
  location: object.isRequired,
  data: object.isRequired,
};

export default AboutMePage;
